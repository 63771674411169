<template>
  <div class="page-content-inner pt-5 pr-lg-0">
    <div class="uk-grid-medium" uk-grid>
      <div class="section-header pb-0 mt-2 uk-width-4-4@m">
        <div class="section-header-left">
          <h3 class="uk-text-truncate"><i class="uil-search-alt"></i> {{ $t('sideNav.Search') }}</h3>
        </div>
      </div>
      <div class="uk-width-5-5">
        <searching @searchFor="searchResults" :searchValue="search"></searching>
        <template v-if="search.trim()">
          <!-- LOADING BOX -->
          <default-loading v-if="page_loading" :minHeight="'80vh'"></default-loading>
          <template v-else>
            <div class="section-header pb-0 mt-2 mb-4">
              <div class="section-header-left">
                <h4 class="uk-text-truncate">
                  <i class="uil-search-alt"></i> "{{ search }}" Aramasında
                  {{
            totalItems(assignments) +
            totalItems(posts) +
            totalItems(collections)
          }}
                  sonuç bulundu.
                </h4>
              </div>
            </div>

            <div class="uk-card-default uk-card-small rounded mt-2" v-if="totalItems(assignments)">
              <div class="section-header-left">
                <h4 class="uk-text-truncate pt-4 pl-4">
                  <i class="uil-file-search-alt"></i> {{ $t("general.trainings_assigned_to_me") }} ({{
            totalItems(assignments)
          }})
                </h4>
              </div>
              <template v-for="(assignment, index) in formattedAssignments">
                <SearchingResultItem @click="openAssignmentDetails" :id="assignment.id"
                  :key="'assignment_list_item_' + index" :title="assignment.name" :image="assignment.cover_image_link"
                  :sub-title="assignment.description" :hashtags="assignment.hashtags"></SearchingResultItem>
              </template>
            </div>

            <div class="uk-card-default uk-card-small rounded mt-2" v-if="CollectionVisible && totalItems(collections)">
              <div class="section-header-left">
                <h4 class="uk-text-truncate pt-4 pl-4">
                  <i class="uil-file-search-alt"></i>
                  {{ $t("sideNav.My_collections") }} ({{
            totalItems(collections)
          }})
                </h4>
              </div>
              <div class="p-3">
                <div class="uk-child-width-1-4@m uk-child-width-1-4@s course-card-grid uk-grid-match" uk-grid>
                  <template v-for="(collection, index) in formattedCollections">
                    <collection :key="'collection_list_item_' + index" @onClickCard="openCollectionDetails"
                      :id="collection.id" :title="collection.name" :image="collection.cover_image_link"
                      :counter="collection.collection_contents_count"></collection>
                  </template>
                </div>
              </div>
            </div>

            <div class="uk-card-default uk-card-small rounded mt-2" v-if="socialPostVisible && totalItems(posts)">
              <div class="section-header-left">
                <h4 class="uk-text-truncate pt-4 pl-4">
                  <i class="uil-file-search-alt"></i> Duvar ({{
            totalItems(posts)
          }})
                </h4>
              </div>

              <template v-for="(post, index) in formattedPosts">
                <wall-item @click="openSocialPost" :key="'social_post_item_' + index" :id="post.id"
                  :image="post.user.photo_link" :name="post.user.name" :time="timeFromNow(post.created_at)" :name-tag="(post.user.name + '.' + post.user.surname).toLowerCase()
            " :description="post.text"></wall-item>
              </template>
            </div>
          </template>
        </template>
        <popular-tags :search="search" @updateSearch="searchResults"></popular-tags>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/core/services";
import moment from "moment";
import DashboardLayout from "@/view/layouts/DashboardLayout";
import Searching from "@/view/components/index/Searching";
import PopularTags from "@/view/components/search/PopularTags";
import SearchingResultItem from "@/view/components/search/SearchingResultItem";
import WallItem from "@/view/components/search/WallItem";
import Collection from "@/view/components/index/Collection";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

import {
  GET_ITEMS as REST_GET_ITEMS,
  LOADING as REST_LOADING,
} from "@/core/services/store/REST.module";

import new_assignment_module, {
  BASE_URL as ASSIGNMENT_BASE_URL,
  MODULE_NAME as ASSIGNMENT_MODULE_NAME,
  ITEMS as ASSIGNMENT_ITEMS,
  GET_ITEMS as ASSIGNMENT_GET_ITEMS,
  LOADING as ASSIGNMENT_LOADING,
} from "@/core/services/store/assignment.module";

import new_social_module, {
  BASE_URL as SOCIAL_BASE_URL,
  MODULE_NAME as SOCIAL_MODULE_NAME,
  ITEMS as SOCIAL_ITEMS,
  GET_ITEMS as SOCIAL_GET_ITEMS,
  LOADING as SOCIAL_LOADING,
} from "@/core/services/store/social_post.module";

const _SOCIAL_MODULE = SOCIAL_MODULE_NAME;
const _ASSIGNMENT_MODULE = ASSIGNMENT_MODULE_NAME;
export default {
  name: "Search",
  components: {
    DashboardLayout,
    Searching,
    PopularTags,
    SearchingResultItem,
    WallItem,
    Collection,
    DefaultLoading,
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(_SOCIAL_MODULE, new_social_module);
    registerStoreModule(_ASSIGNMENT_MODULE, new_assignment_module);
  },
  data() {
    return {
      collectionUrl: "api/collection",
      search: "",
      page: 1,
      per_page: 10,
      order: "desc",
      sort: "id",
      collections: [],
    };
  },
  computed: {
    posts: {
      get() {
        return store.getters[SOCIAL_MODULE_NAME + "/" + SOCIAL_ITEMS];
      },
      set(value) { },
    },
    page_loading: {
      get() {
        return (
          store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_LOADING] ||
          store.getters[ASSIGNMENT_MODULE_NAME + "/" + SOCIAL_LOADING] ||
          this.$store.getters[REST_LOADING]
        );
      },
      set(value) { },
    },
    assignments: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_ITEMS];
      },
      set(value) { },
    },
    formattedPosts() {
      let items = this.posts;
      if (items === null || !("data" in items)) return [];

      return items.data;
    },
    formattedAssignments() {
      let results = [];
      if (this.assignments === null || !("data" in this.assignments)) return [];
      this.assignments.data.forEach((currentItem) => {
        let public_contents_tags = this.getHashtagsFromContents(
          currentItem.contents,
          [],
          []
        );
        let hashtags = public_contents_tags.hashtags;
        let existing_ids = public_contents_tags.existing_ids;
        currentItem.programs.forEach((program) => {
          let program_contents_tags = this.getHashtagsFromContents(
            program.contents,
            hashtags,
            existing_ids
          );
          hashtags = program_contents_tags.hashtags;
          existing_ids = program_contents_tags.existing_ids;
        });

        results.push({
          id: currentItem.id,
          name: currentItem.name,
          cover_image_link: currentItem.cover_image_link,
          hashtags: hashtags,
          description: currentItem.description ? currentItem.description : "-",
          has_gift: currentItem.has_gift
            ? this.$t("general.yes")
            : this.$t("general.no"),
          created_at: currentItem.created_at
            ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
          start_end:
            currentItem.start_date && currentItem.end_date
              ? moment(currentItem.start_date).format("DD-MM-YYYY") +
              " / " +
              moment(currentItem.end_date).format("DD-MM-YYYY")
              : "-",
        });
      });

      return results;
    },
    formattedCollections() {
      let items = this.collections;
      if (items === null || !("data" in items)) return [];

      return items.data;
    },
  },
  methods: {
    updateSearch(variable) {
      this.search = variable;
    },
    getHashtagsFromContents(contents, hashtags_list, existing_ids) {
      contents.forEach((content) => {
        content.hashtags.forEach((hashtag) => {
          if (!existing_ids.includes(hashtag.id)) {
            existing_ids.push(hashtag.id);
            hashtags_list.push({ id: hashtag.id, name: hashtag.name });
          }
        });
      });
      return { hashtags: hashtags_list, existing_ids: existing_ids };
    },
    getSocialPosts() {
      if (!this.socialPostVisible) {
        return false;
      }
      let self = this;
      this.$store.dispatch(SOCIAL_MODULE_NAME + "/" + SOCIAL_GET_ITEMS, {
        url: SOCIAL_BASE_URL,
        filters: {
          like: self.search,
          page: self.page,
          per_page: self.per_page,
          sort: self.sort,
          order: self.order,
        },
      });
    },
    getCollectionResult() {
      if (!this.CollectionVisible) {
        return false;
      }
      let self = this;
      this.$store
        .dispatch(REST_GET_ITEMS, {
          url: this.collectionUrl,
          filters: {
            search: self.search,
            page: self.page,
            per_page: self.per_page,
            sort: self.order,
            order_by: self.sort,
          },
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
          }
          if (typeof result.data !== "undefined") {
            self.collections = result.data.my_collections;
          }
        });
    },
    getAssignmentResult() {
      let self = this;
      this.$store.dispatch(
        ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_GET_ITEMS,
        {
          url: ASSIGNMENT_BASE_URL,
          filters: {
            search: self.search,
            page: self.page,
            per_page: self.per_page,
            sort: self.order,
            order: self.sort,
            by_user: true,
            hashtags: true,
            with_expired: false,
          },
        }
      );
    },
    openAssignmentDetails(id) {
      this.$router.push({ name: "user.assignment-detail", params: { id: id } });
    },
    openSocialPost(id) {
      this.$router.push({ name: "User.wall", params: { id: id } });
    },
    openCollectionDetails(id) {
      this.$router.push({ name: "user.collection-detail", params: { id: id } });
    },
    totalItems(items) {
      if (items === null || !("data" in items)) return 0;
      return items.total;
    },
    searchResults(val) {
      if (this.search !== val.trim() && val.trim() !== "") {
        this.search = val.trim();
        this.getAllResults();
      }
      this.search = val.trim();
    },
    getAllResults() {
      this.getAssignmentResult();
      this.getSocialPosts();
      this.getCollectionResult();
    },
    timeFromNow(date) {
      return moment(date).fromNow();
    },
  },
  mounted() { },
};
</script>

<style scoped></style>
